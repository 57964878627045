import { createContext, useContext } from "react";

export type DesignContextType = {
  anchorBlock?: {
    verticalLine?: string | string[];
    summaryItem?: string | string[];
  };
  authorProfile?: {
    verticalLine?: string | string[];
  };
  button?: {
    primary?: string | string[];
    secondary?: string | string[];
  };
  callBlock?: {
    verticalLine?: string | string[];
  };
  divider?: {
    secondary?: string | string[];
  };
  figureCaptions?: {
    imageCaption?: string | string[];
  };
  heading?: {
    headingBase?: string | string[];
    headingSplit?: string | string[];
  };
  sectionHeading?: {
    container?: string | string[];
  };
  homeContentBlockB?: {
    contentBlockContainer?: string | string[];
    contentBlockScroll?: string | string[];
  };
  magazineListing?: {
    border?: string | string[];
    divider?: string | string[];
    verticalLine?: string | string[];
    carouselContent?: string | string[];
  };
  navLinks?: {
    verticalLineHeader?: string | string[];
    verticalLineFooter?: string | string[];
    itemLink?: string | string[];
  };
  relatedItem?: {
    verticalLine?: string | string[];
    firstItem?: string | string[];
  };
  stickyTitle?: {
    font?: string | string[];
  };
  textLink?: string | string[];
  videoGallery?: {
    verticalLine?: string | string[];
  };
  categoryLayout?: {
    sectionContainer?: string | string[];
    anchorBlock?: string | string[];
    tabContainer?: string | string[];
    categoryHeading?: string | string[];
    mainContent?: {
      item?: string | string[];
    };
  };
  navbar?: {
    primaryItems?: string | string[];
    logo?: string | string[];
    articleSection?: string | string[];
    dropDown?: string | string[];
  };
  contentHubLayout?: {
    userSubmissionBox?: string | string[];
    articleMastHeadText?: string | string[];
  };
  label?: string | string[];
  tags?: string | string[];
  contentBlock?: {
    cta?: string | string[];
  };
  homeAnchorBlock?: string | string[];
  latestArticle?: {
    landscape?: string | string[];
    itemContainerGridColumn?: string | string[];
    container?: string | string[];
  };
  errorLayout?: {
    container?: string | string[];
    errorLayoutHeadingContainer?: string | string[];
  };
  aboutUs?: {
    heading?: string | string[];
    standfirst?: string | string[];
  };
  singlePost?: {
    readmore?: string | string[];
  };
  socialIcons?: {
    footerContainer?: string | string[];
    megaMenuContainer?: string | string[];
  };
  megaMenu?: {
    columnItem?: string | string[];
    details?: string | string[];
    summary?: {
      container?: string | string[];
      buttonItem?: string | string[];
    };
  };
  subAnchor?: {
    item?: string | string[];
  };
};

const CustomClassContext = createContext<DesignContextType | null>(null);

const useCustomClassContext = () => useContext(CustomClassContext);

export { useCustomClassContext, CustomClassContext };
