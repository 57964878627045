import { TeaserDataApiResponse } from "@app/types/ServerCustomContext";
import { mapTeaserDataToLatestProps } from "@app/utils/mapTeaserDataToProps";
import MetaData from "@components/Modules/MetaData";
import PubBaseLayout from "@pub/BaseLayout";
import { configError, configLatestStory, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  LatestArticle,
  Typography,
  useCustomClassContext,
} from "@sphtech/dmg-design-system";
import { NoSSR } from "@sphtech/web2-core/components";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import cx from "classnames";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";

import styles from "./ErrorLayout.module.css";
import ErrorLayoutContainer from "./ErrorLayoutContainer";
import { textForStatusCode } from "./ErrorLayoutTypes";

const { Heading, Body } = Typography;

function RenderLatestArticles() {
  const { data } = useSWR<TeaserDataApiResponse>(
    "/_plat/api/teaser/latest?size=3",
  );
  const customClass = useCustomClassContext();

  return (
    !!data?.results?.length && (
      <div
        className={cx(
          styles.bodyContainer,
          styles.gutterHorizontal,
          styles.relatedContentBlock,
        )}
      >
        <Heading.SubHeading3>
          <div className={cx(customClass?.relatedItem?.verticalLine)}>
            {configError.relatedTitle ||
              "Perhaps you might be interested in these."}
          </div>
        </Heading.SubHeading3>
        {configError.relatedAltTitle && (
          <Heading.SubHeading3Alt>
            <div className={cx(customClass?.relatedItem?.verticalLine)}>
              {configError.relatedAltTitle}
            </div>
          </Heading.SubHeading3Alt>
        )}
        <div
          className={cx(styles.content, customClass?.relatedItem?.firstItem)}
        >
          {data.results
            .map((teaser) => mapTeaserDataToLatestProps(teaser))
            .map((data, index) => (
              <LatestArticle
                key={index}
                {...data}
                mode={
                  (Array.isArray(configLatestStory.errorLayoutMode)
                    ? configLatestStory.errorLayoutMode[index]
                    : configLatestStory.errorLayoutMode) || "portrait"
                }
                portraitImageWidth={configLatestStory.portraitImageWidth}
                landscapeImageWidth={configLatestStory.landscapeImageWidth}
                placeholders={placeholders}
                displayPublishDate={configTeaser.publishDate}
              />
            ))}
        </div>
      </div>
    )
  );
}

const ErrorLayout = ({
  response,
  clientSideError,
}: {
  response: TRouteWithRedirect<unknown, unknown>;
  clientSideError?: boolean;
}): ReactNode => {
  const statusCode = response.statusCode;
  const text = clientSideError
    ? textForStatusCode.clientSideError
    : statusCode === 400 || statusCode === 404 || statusCode === 503
      ? textForStatusCode[statusCode]
      : textForStatusCode[500];

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (response.type === ResponseType.REDIRECT) {
      navigate(response.target);
      return;
    }
  }, [response, navigate]);

  // DMG-3368
  // Treat 404 pages with old pagination path (/page/) as noindex
  // TODO: Apply to all 404 pages if needed
  const robots =
    statusCode === 404 && /\/page\/\d+/.test(location.pathname)
      ? "noindex"
      : undefined;

  return (
    <PubBaseLayout ads={false}>
      <MetaData
        title={text.pageTitle}
        type="WebPage"
        path={location.pathname}
        robots={robots}
      />
      {response.type === ResponseType.REDIRECT ? (
        <div
          className={cx(
            styles.bodyContainer,
            styles.gutterHorizontal,
            styles.gutterVertical,
          )}
        >
          <div className={styles.notFoundTopContainer}>
            <Heading.Title>You are being redirected</Heading.Title>
            <Body>
              <a href={response.target}>
                <u>Please click here if your browser does not redirect you.</u>
              </a>
            </Body>
          </div>
        </div>
      ) : (
        <>
          <ErrorLayoutContainer text={text} statusCode={statusCode} />
          {statusCode === 404 && (
            <NoSSR>
              <RenderLatestArticles />
            </NoSSR>
          )}
        </>
      )}
    </PubBaseLayout>
  );
};

export default ErrorLayout;
