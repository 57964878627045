import { TeaserData } from "@app/types/ServerCustomContext";
import { useInView } from "@components/hooks/hook";
import { configLatestStory, configTeaser } from "@pub/config";
import { configSearch } from "@pub/config";
import placeholders from "@pub/placeholders";
import { Ads, LatestArticle, Loader } from "@sphtech/dmg-design-system";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import cx from "classnames";
import { ReactNode, useEffect } from "react";

import styles from "./search.module.css";

type Props = {
  data: TeaserData[];
  showLeaderboard?: boolean;
  loadMore?: () => void;
  isLoadingMore: boolean;
};

export default function SearchResults({
  data,
  showLeaderboard = false,
  loadMore,
  isLoadingMore,
}: Props): ReactNode {
  const { ref, inView } = useInView({ threshold: 0.1 });
  useEffect(() => {
    if (inView && loadMore) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <>
      <section className={cx(styles.bodyContainer, styles.gutterBottom)}>
        {showLeaderboard && <Ads.AdsMidContent />}

        <div className={styles.mainContentBlockContainer}>
          <div
            className={cx(
              styles.mainContentBlockLayout,
              styles[configLatestStory.mainContentBlockDisplay],
            )}
          >
            {data.map((teaser, index) => (
              <div
                key={index}
                className={cx(
                  styles.mainContentBlockItem,
                  styles[configLatestStory.mainContentBlockMode],
                )}
              >
                <LatestArticle
                  key={index}
                  {...teaser}
                  imageSize="small"
                  mode={configLatestStory.mainContentBlockMode}
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                  description={
                    configTeaser.withDescription
                      ? teaser.description
                      : undefined
                  }
                />
              </div>
            ))}
          </div>
          <div className={styles.sidebar}>
            <Ads.AdsSideBar />
          </div>
        </div>
      </section>

      {/* { Loading more } */}
      {(loadMore || isLoadingMore) && (
        <div
          ref={ref}
          className={cx(styles.bodyContainer, styles.gutterBottom)}
        >
          {isLoadingMore && (
            <Loader label={configSearch.loadMoreText || LOAD_MORE_TEXT} />
          )}
        </div>
      )}
    </>
  );
}
