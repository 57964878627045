import { TeaserData } from "@app/types/ServerCustomContext";
import AppExploreMore, {
  AppExploreMoreProps,
} from "@components/Modules/AppExploreMore/AppExploreMore";
import {
  configCategory,
  configExploreMore,
  configLatestStory,
  configTeaser,
} from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  Divider,
  LatestArticle,
  SponsorshipBox,
  useCustomClassContext,
  UserSubmissionBox,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./category.module.css";

type Props = {
  data: {
    exploreMore?: AppExploreMoreProps;
    mainContentBlock?: TeaserData[];
    lb1?: ReactNode;
    imu?: ReactNode;
    userSubmissionBox?: UserSubmissionBox;
  };
};

export default function CategoryStoryListingLayout({ data }: Props): ReactNode {
  const customClass = useCustomClassContext();
  const mainContentBlockMode =
    configCategory.latestStory?.mainContentBlockMode ||
    configLatestStory.mainContentBlockMode;

  if (data.mainContentBlock && data.mainContentBlock.length > 0) {
    const ExploreMore = data.exploreMore && (
      <AppExploreMore
        {...data.exploreMore}
        headingText={configExploreMore.heading}
        render={(children) => (
          <div className={styles.exploreMoreContainer}>
            {!configExploreMore.hideDivider && <Divider />}
            {children}
            {!configExploreMore.hideDivider && <Divider />}
          </div>
        )}
      />
    );
    return (
      <section className={cx(styles.bodyContainer, styles.gutterBottom)}>
        <div className={styles.mainContentBlockContainer}>
          {/* Contains list of LatestArticle */}
          <div
            className={cx(
              styles.mainContentBlockLayout,
              styles[configLatestStory.mainContentBlockDisplay],
            )}
          >
            {data.userSubmissionBox && (
              <div className={styles.userSubmissionBox}>
                {
                  <SponsorshipBox
                    inPage={true}
                    {...data.userSubmissionBox}
                    buttonType="primary"
                  />
                }
              </div>
            )}
            {data.mainContentBlock.map((teaser, index) => (
              <div
                key={index}
                className={cx(
                  styles.mainContentBlockItem,
                  styles[mainContentBlockMode],
                  customClass?.categoryLayout?.mainContent?.item,
                )}
              >
                <LatestArticle
                  key={index}
                  {...teaser}
                  mode={mainContentBlockMode}
                  portraitImageWidth={configLatestStory.portraitImageWidth}
                  landscapeImageWidth={configLatestStory.landscapeImageWidth}
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                  mobileMode={configTeaser.mobileMode}
                  CustomHeadingElement="h2"
                  dateAfter={configTeaser.dateAfter}
                />
                {index === configCategory.exploreMorePosition && (
                  <div className={styles.exploreMorePosition}>
                    {ExploreMore}
                  </div>
                )}
                {index === configCategory.adPositioning && (
                  <div className={styles.mobileAdvertisement}>
                    <Ads.AdsSideBarMobile />
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Contains Ads */}
          <div
            className={cx(
              styles.sidebar,
              typeof configCategory.exploreMorePosition === "number" &&
                styles.exploreMoreSideBar,
            )}
          >
            {data.exploreMore && ExploreMore}
            {configCategory.adPositioning ? (
              <Ads.AdsSideBarDesktop />
            ) : (
              <Ads.AdsSideBar />
            )}
          </div>
        </div>
      </section>
    );
  }
}
